import React from "react";
import Texts from "../../../utils/lang-utils";
import stamp from '../../../areas/main/assets/images/stamp-eatella-qr-ordering.png'
import mocImage from "../../../areas/main/assets/images/resources/restaurant-barcode-qr-code-menu.png"
import urls from "../../../areas/main/assets/urls";

const Why = () => {
  return (
    <><section className="cta-one">
      <div className="container">
        <div className="cta-one__content">
          <div className="block-title text-center">
            <h2 className="block-title__title" dangerouslySetInnerHTML={{ __html: Texts.QROrdering.WhyTitle }}></h2>

          </div>

        </div>
        <div className="cta-one__content  text-center">
          <h3 className="block-title__text subtitle" style={{ marginBottom: "1rem" }} dangerouslySetInnerHTML={{ __html: Texts.QROrdering.WhySubtitle }}></h3>
        </div>
        <div className="row">

          <div className=" col-md-6">
            <h4><i className="fa fa-check"></i> {Texts.QROrdering.WhyBullet1Title}</h4>

            <p>{Texts.QROrdering.WhyBullet1Text}</p>
            <h4><i className="fa fa-check"></i> {Texts.QROrdering.WhyBullet2Title}</h4>
            <p>{Texts.QROrdering.WhyBullet2Text}</p>
          </div>

          <div className=" col-md-6">
            <h4><i className="fa fa-check"></i> {Texts.QROrdering.WhyBullet3Title}</h4>
            <p>{Texts.QROrdering.WhyBullet3Text}</p>
            <h4><i className="fa fa-check"></i> {Texts.QROrdering.WhyBullet4Title}</h4>
            <p>{Texts.QROrdering.WhyBullet4Text}</p>
          </div>

        </div>

        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-8 col-xl-12 text-center">
            <h3 className="block-title__text subtitle" style={{ marginBottom: "1rem", marginTop: "3rem" }} dangerouslySetInnerHTML={{ __html: Texts.QROrdering.WhySubtitle1 }}></h3>
            <i dangerouslySetInnerHTML={{ __html: Texts.QROrdering.WhyQuote }}></i>
          </div>
        </div>

        <div className="row justify-content-center">

          <div className="col-md-12 col-lg-8 col-xl-6 mt-3 ">
            <div className="mt-5" style={{ paddingRight: '20px' }}>
              <h4><i className="fa fa-check"></i> {Texts.QROrdering.WhyBullet5Title}</h4>
              <p>{Texts.QROrdering.WhyBullet5Text}</p>
              <h4><i className="fa fa-check"></i> {Texts.QROrdering.WhyBullet6Title}</h4>
              <p>{Texts.QROrdering.WhyBullet6Text}</p>
              <h4><i className="fa fa-check"></i> {Texts.QROrdering.WhyBullet7Title}</h4>
              <p dangerouslySetInnerHTML={{ __html: Texts.QROrdering.WhyBullet7Text }} />

            </div>
          </div>

          <div className="col-md-6 mobile-hide d-none d-xl-block">
            <img src={mocImage} alt="restaurant barcode and qr code menu" style={{
                  marginTop: 40,
                  borderRadius: 20,
                  boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
            }} />
          </div>

        </div>
        <div>
          <div className="text-center mt-5">
            
            <img src={stamp} className="stamp" style={{ bottom: '-250px' }} ></img>

            <a href={urls.onboarding} className="thm-btn">
              <span>{Texts.GetStarted}</span>
            </a>
          </div>

        </div>

      </div>
    </section>
    </>

  );
};
export default Why;
